import React from 'react';
import styled from 'styled-components';

interface DatoCmsCustomHeadlineProps {
   marginTop: number;
   marginBottom: number;
   videoUrl: string;
   id: string;
}

function DatoCmsBackgroundVideo({
  videoUrl, marginTop, marginBottom, id,
}: DatoCmsCustomHeadlineProps) {
  return (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <Video autoPlay muted loop id={`video_${id}`}>
        <source src={videoUrl} type="video/mp4" />
      </Video>
    </Wrapper>
  );
}

const Wrapper = styled.div<{marginTop: number; marginBottom: number}>`
   margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
   margin-top: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
`;

const Video = styled.video`
  display: block;
  width: 100%;
   
`;

export default DatoCmsBackgroundVideo;
