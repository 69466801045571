/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Logo from 'components/Logo';
import devices from 'theme/devices';

interface CaseHeaderProps {
   alt: string;
   image:IGatsbyImageData;
   showTitleInCase: boolean;
   showFeaturedImageInCase: boolean;
   title: string;
   logo: string;
   lang: string;
}

interface TitleStyledProps {
   screenReadOnly: boolean;
}

function CaseHeader({
  alt, logo, lang, title, showFeaturedImageInCase, image, showTitleInCase,
}: CaseHeaderProps) {
  return (
    <Header>
      <StyledLink to={lang === 'en' ? '/en' : '/'} state={{ fromCasePage: true }}>
        <Logo version={logo} />
      </StyledLink>
      {showFeaturedImageInCase && <GatsbyImage image={image} alt={alt} />}
      <Title screenReadOnly={!showTitleInCase}>{title}</Title>
    </Header>
  );
}

const StyledLink = styled(Link)`
   display: block;
   position: fixed;
   z-index: 1;
   top: 0;
   left: 0;
   padding: 20px;

   &, &:hover {
      text-decoration: underline;
   }

   ${devices.mobile} {
      padding: 24px 30px;
   }

   ${devices.mobileL} {
      padding: 30px 40px;
   }

   ${devices.tablet} {
      padding: 40px;
      font-size: 15px;
   }

   ${devices.tabletXL} {
      padding: 60px;
   }
`;

const Title = styled.h1<TitleStyledProps>`
   max-width: 960px;
   width: calc(100% - 40px);
   margin: 40px auto;
   font-size: 32px;
   line-height: 1.5;

   ${({ screenReadOnly }) => screenReadOnly && css`
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
   `}

   ${devices.mobile} {
      width: calc(100% - 60px);
   }

   ${devices.mobileL} {
      width: calc(100% - 80px);
      font-size: 36px;
   }

   ${devices.tabletXL} {
      margin: 60px auto;
      font-size: 42px;
      width: 100%;
   }
`;

const Header = styled.header`
   position: relative;
`;

export default CaseHeader;
