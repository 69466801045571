import React from 'react';
import styled, { css } from 'styled-components';
import { StructuredText } from 'react-datocms';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import devices from 'theme/devices';

interface DatoCmsPhotoWithTextProps {
   photo: {
      alt: string;
      image: IGatsbyImageData;
      location: string;
   };
   text: {
      fontSize: number;
      lineHeight: number;
      verticalAlign: string;
      content: any;
   };
   block: {
      marginTop:number;
      marginBottom: number;
      spaceBetweenColumns: number;
   }
}

function DatoCmsPhotoWithText({
  photo, text, block,
}: DatoCmsPhotoWithTextProps) {
  return (
    <Wrapper
      verticalAlign={text.verticalAlign}
      reverse={photo.location === 'right'}
      marginTop={block.marginTop}
      marginBottom={block.marginBottom}
      spaceBetweenColumns={block.spaceBetweenColumns}
    >
      <Column>
        <GatsbyImage image={photo.image} alt={photo.alt} />
      </Column>
      <Column>
        <StructuredText data={text.content} />
      </Column>
    </Wrapper>
  );
}

const Column = styled.div`
   p, h1, h2, h3, h4, h5, h6 {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
   }

   ${devices.tabletL} {
      width: 50%;
   }
`;

interface WrapperStyledProps {
   verticalAlign: string;
   reverse: boolean;
   marginTop: number;
   marginBottom: number;
   spaceBetweenColumns: number;
}

const Wrapper = styled.div<WrapperStyledProps>`
   max-width: 960px;
   width: calc(100% - 40px);
   margin: 0 auto;
   display: flex;
   flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};

   ${devices.tabletL} {
      flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
   }

   ${({ verticalAlign }) => {
    switch (verticalAlign) {
      case 'bottom':
        return css`
            align-items: flex-end;
         `;
      case 'middle':
        return css`
            align-items: center;
         `;
      case 'top':
      default:
        return css`
            align-items: flex-start;
         `;
    }
  }}

  ${({ marginTop, marginBottom }) => css`
      margin-top: ${`${marginTop}px`};
      margin-bottom: ${`${marginBottom}px`};
  `}

   ${devices.mobile} {
      width: calc(100% - 60px);
   }

   ${devices.mobileL} {
      width: calc(100% - 80px);
   }

   ${devices.tabletXL} {
      width: 100%;
   }

   ${({ spaceBetweenColumns, reverse }) => (reverse ? css`
      ${Column} {
         &:last-of-type {
            margin-bottom: ${`${spaceBetweenColumns}px`};

            ${devices.tabletL} {
               margin-bottom: 0;
               margin-right: ${`${spaceBetweenColumns / 2}px`};
            }
         }

         &:first-of-type {
            ${devices.tabletL} {
               margin-left: ${`${spaceBetweenColumns / 2}px`};
            }
         }
      }
   ` : css`
      ${Column} {
         &:first-of-type {
            margin-bottom: ${`${spaceBetweenColumns}px`};

            ${devices.tabletL} {
               margin-bottom: 0;
               margin-right: ${`${spaceBetweenColumns / 2}px`};
            }
         }

         &:last-of-type {
            ${devices.tabletL} {
               margin-left: ${`${spaceBetweenColumns / 2}px`};
            }
         }
      }
   `)}
`;

export default DatoCmsPhotoWithText;
