import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { StructuredText } from 'react-datocms';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { graphql, Link } from 'gatsby';
import devices from 'theme/devices';

import SocialMedia from 'components/SocialMedia';
import { StateProps, State } from '..';
import CaseHeader from './CaseHeader/CaseHeader';
import DatoCmsFullWidthImage from './DatoCmsFullWidthImage/DatoCmsFullWidthImage';
import DatoCmsContentImage from './DatoCmsContentImage/DatoCmsContentImage';
import DatoCmsPhotoWithText from './DatoCmsPhotoWithText/DatoCmsPhotoWithText';
import DatoCmsYoutubePlayer from './DatoCmsYoutubePlayer/DatoCmsYoutubePlayer';
import DatoCmsCustomParagraph from './DatoCmsCustomParagraph/DatoCmsCustomParagraph';
import DatoCmsCustomHeadline from './DatoCmsCustomHeadline/DatoCmsCustomHeadline';
import DatoCmsBackgroundVideo from './DatoCmsBackgroundVideo/DatoCmsBackgroundVideo';

interface Post {
  originalId: string;
  slug: string;
}

interface GetSlugs {
  previousPost: string | null;
  backHome: string | null;
  nextPost: string | null;
}

interface CaseTemplateProps {
  data: {
    datoCmsCasePage: {
      nextProject: string;
      previousProject: string;
      backHome: string;
    };
    datoCmsPortfolio: {
      logo: string;
      lang: string;
      order: number;
      metaDescription: string;
      originalId: string;
      showFeaturedImageInCase: boolean;
      showTitleInCase: boolean;
      slideCover: {
        alt: string;
        gatsbyImageData: IGatsbyImageData;
      }
      slideTitle: string;
      slug: string;
      title: string;
      content: any;
      featuredPhoto: {
        alt: string;
        gatsbyImageData: IGatsbyImageData;
      }
    }
    allDatoCmsPortfolio: {
      nodes: Post[];
    }
  }
}

const getSlugs = (allPost: Post[], currentPostId: string, lang: string): GetSlugs => {
  const currentPostIndex = allPost.findIndex((post) => post.originalId === currentPostId);
  const postInCurrentLang = allPost.map((post) => ({ ...post, slug: `${lang === 'en' ? '/en/' : '/'}${post.slug}` }));
  const previousPostIndex = currentPostIndex - 1;
  const nextPostIndex = currentPostIndex + 1;

  return {
    previousPost: postInCurrentLang[previousPostIndex]?.slug || null,
    backHome: lang === 'en' ? '/en' : '/',
    nextPost: postInCurrentLang[nextPostIndex]?.slug || null,
  };
};

function CaseTemplate({ data }: CaseTemplateProps) {
  const context = useContext<StateProps>(State);

  const slugs = getSlugs(
    data.allDatoCmsPortfolio.nodes,
    data.datoCmsPortfolio.originalId,
    data.datoCmsPortfolio.lang,
  );

  useEffect(() => {
    context.turnOffIntroAnimation();
  }, []);

  return (
    <Wrapper>
      <Helmet
        htmlAttributes={{
          lang: data.datoCmsPortfolio.lang,
        }}
        title={`Black is Black | ${data.datoCmsPortfolio.title}`}
        meta={[
          {
            name: 'description',
            content: data.datoCmsPortfolio.metaDescription,
          },
          {
            name: 'author',
            content: 'Black is Black',
          },
        ]}
      />
      <SocialMedia white={false} />
      <CaseHeader
        lang={data.datoCmsPortfolio.lang}
        logo={data.datoCmsPortfolio.logo}
        title={data.datoCmsPortfolio.title}
        showTitleInCase={data.datoCmsPortfolio.showTitleInCase}
        showFeaturedImageInCase={data.datoCmsPortfolio.showFeaturedImageInCase}
        image={getImage(data.datoCmsPortfolio.featuredPhoto.gatsbyImageData) as IGatsbyImageData}
        alt={data.datoCmsPortfolio.featuredPhoto.alt}
      />
      <Main>
        <StructuredText
          data={data.datoCmsPortfolio.content}
          renderBlock={({ record }: any) => {
            switch (record.__typename) {
              case 'DatoCmsFullWidthImage':
                return (
                  <DatoCmsFullWidthImage
                    image={getImage(record.fullWidthImage.gatsbyImageData) as IGatsbyImageData}
                    alt={record.fullWidthImage.alt}
                    marginTop={record.marginTop}
                    marginBottom={record.marginBottom}
                  />
                );
              case 'DatoCmsContentImage':
                return (
                  <DatoCmsContentImage
                    image={getImage(record.contentImage.gatsbyImageData) as IGatsbyImageData}
                    alt={record.contentImage.alt}
                    marginTop={record.marginTop}
                    marginBottom={record.marginBottom}
                  />
                );
              case 'DatoCmsYoutubePlayer':
                return (
                  <DatoCmsYoutubePlayer
                    providerUid={record.youtubePlayer.providerUid}
                    marginBottom={record.marginBottom}
                    marginTop={record.marginTop}
                    provider={record.youtubePlayer.provider}
                    title={record.youtubePlayer.title}
                  />
                );
              case 'DatoCmsPhotoWithText':
                return (
                  <DatoCmsPhotoWithText
                    photo={{
                      alt: record.photo.alt,
                      image: getImage(record.photo.gatsbyImageData) as IGatsbyImageData,
                      location: record.photoLocation,
                    }}
                    text={{
                      fontSize: record.fontSize,
                      lineHeight: record.lineHeight,
                      verticalAlign: record.verticalAlign,
                      content: record.text,
                    }}
                    block={{
                      marginTop: record.marginTop,
                      marginBottom: record.marginBottom,
                      spaceBetweenColumns: record.spaceBetweenColumns,
                    }}
                  />
                );
              case 'DatoCmsAdvancedEditor':
                return <>{parse(record.editor)}</>;

              case 'DatoCmsCustomParagraph':
                return (
                  <DatoCmsCustomParagraph
                    paragraph={record.content}
                    marginBottom={record.marginBottom}
                    marginTop={record.marginTop}
                    lineHeight={record.lineHeight}
                    fontSize={record.fontSize}
                  />
                );
              case 'DatoCmsCustomHeadline':
                return (
                  <DatoCmsCustomHeadline
                    headline={record.headline}
                    marginBottom={record.marginBottom}
                    marginTop={record.marginTop}
                    lineHeight={record.lineHeight}
                    fontSize={record.fontSize}
                  />
                );
              case 'DatoCmsBackgroundVideo':
                return (
                  <DatoCmsBackgroundVideo
                    marginTop={record.marginTop}
                    marginBottom={record.marginBottom}
                    videoUrl={record.video.url}
                    id={record.id}
                  />
                );
              default:
                return null;
            }
          }}
        />
      </Main>
      <Navigation>
        <LinkWrapper>
          {!!slugs.previousPost && (
            <StyledLink to={slugs.previousPost}>
              {data.datoCmsCasePage.previousProject}
            </StyledLink>
          )}
        </LinkWrapper>
        <LinkWrapper>
          <StyledLink
            to={slugs.backHome as string}
            state={{ fromCasePage: true }}
          >
            {data.datoCmsCasePage.backHome}

          </StyledLink>
        </LinkWrapper>
        <LinkWrapper>
          {!!slugs.nextPost && (
            <StyledLink to={slugs.nextPost}>
              {data.datoCmsCasePage.nextProject}
            </StyledLink>
          )}
        </LinkWrapper>
      </Navigation>
    </Wrapper>
  );
}

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 33.3333%;

  &:first-of-type {
    justify-content: flex-start;
  }

  &:last-of-type {
    justify-content: flex-end;
  }
`;

const Navigation = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  padding: 20px;

  ${devices.mobile} {
    padding: 30px 90px 24px 40px;
  }

  ${devices.tablet} {
    padding: 40px 134px 34px 140px;
  }

  ${devices.desktop} {
    padding: 160px 254px 98px 260px;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  font-weight: bold;
  font-size: 13px;
  color:${({ theme }) => theme.black};
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }

  ${devices.mobile} {
    font-size: 18px;
  }

  ${devices.mobileL} {
    font-size: 22px;
  }

  ${devices.desktop} {
    font-size: 27px;
  }
`;

const Wrapper = styled.div`
  background: #fff;
  color: #000;
  min-height: 100vh;
`;

const Main = styled.main`
  h1, h2, h3, h4, h5, h6, p {
    width: calc(100% - 40px);
    max-width: 960px;
    margin: 1em auto;
    line-height: 1.5;

    ${devices.mobile} {
      width: calc(100% - 60px);
      margin: 1em auto;
    }

    ${devices.mobileL} {
      width: calc(100% - 80px);
      margin: 1em auto;
    }

    ${devices.tabletXL} {
      width: 100%;
      margin: 1em auto;
    }
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    font-size: 18px;
  }

  ol, ul {
    max-width: 960px;
    width: calc(100% - 40px);
    margin: 1.5em auto;

    ${devices.mobile} {
      width: calc(100% - 60px);
    }

    ${devices.mobileL} {
      width: calc(100% - 80px);
    }

    ${devices.tabletXL} {
      width: 100%;
    }

    li {
      padding: 0;
      margin: 0;

      > p {
        padding: 0;
        margin: 0;
      }
    }
  }

  mark {
    background-color: #F50253;
  }

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const query = graphql`
  query MyQuery($originalId: String, $lang: String) {
    allDatoCmsPortfolio(filter: {lang: {eq: $lang}}, sort: {fields: order}) {
      nodes {
        originalId
        slug
      }
    }
    datoCmsCasePage(lang: {eq: $lang}) {
      nextProject
      previousProject
      backHome
    }
    datoCmsPortfolio(originalId: {eq: $originalId}) {
      lang
      logo
      order
      metaDescription
      featuredPhoto {
        alt
        gatsbyImageData
      }
      content {
        blocks {
          __typename
          ... on DatoCmsFullWidthImage {
            id: originalId
            fullWidthImage {
              alt
              gatsbyImageData
            }
            marginBottom
            marginTop
          }
          __typename
          ... on DatoCmsContentImage {
            id: originalId
            contentImage {
              alt
              gatsbyImageData(width: 960)
            }
            marginBottom
            marginTop
          }
          __typename
          ... on DatoCmsPhotoWithText {
            id: originalId
            spaceBetweenColumns
            marginTop
            marginBottom
            verticalAlign
            fontSize
            lineHeight
            text {
              value
              blocks
            }
            photoLocation
            photo {
              alt
              gatsbyImageData(width: 480)
            }
          }
          __typename
          ... on DatoCmsYoutubePlayer {
            id: originalId
            marginTop
            marginBottom
            youtubePlayer {
              provider
              providerUid
              thumbnailUrl
              title
              width
              url
              height
            }
          }
          __typename
          ... on DatoCmsAdvancedEditor {
            id: originalId
            editor
          }
          __typename
          ... on DatoCmsCustomParagraph {
            id: originalId
            marginTop
            marginBottom
            lineHeight
            fontSize
            content {
              blocks
              value
            }
          }
          __typename
          ... on DatoCmsCustomHeadline {
            id: originalId
            marginTop
            marginBottom
            lineHeight
            fontSize
            headline {
              blocks
              value
            }
          }
          __typename
          ... on DatoCmsBackgroundVideo {
            id: originalId
            marginTop
            marginBottom
            video {
              title
              url
            }
          }
        }
        value
      }
      originalId
      showFeaturedImageInCase
      showTitleInCase
      slideCover {
        alt
        gatsbyImageData(width: 540)
      }
      slideTitle
      slug
      title
    }
  }
`;

export default CaseTemplate;
