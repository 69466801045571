import React from 'react';
import styled, { css } from 'styled-components';
import devices from 'theme/devices';

interface DatoCmsVideoPlayerProps {
  marginBottom: number;
  marginTop: number;
  providerUid: string;
  provider: string;
  title: string;
}

function DatoCmsVideoPlayer({
  marginBottom, marginTop, provider, providerUid, title,
}: DatoCmsVideoPlayerProps) {
  return provider === 'youtube' ? (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <iframe
        width="960"
        height="600"
        src={`https://www.youtube.com/embed/${providerUid}`}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Wrapper>
  ) : (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <Error>
        Provider
        <strong>{provider}</strong>
        {' '}
        is not supported. Please contact.
      </Error>
    </Wrapper>
  );
}

interface WrapperStyledProps {
  marginTop: number;
  marginBottom: number;
}

const Wrapper = styled.div<WrapperStyledProps>`
   margin: 0 auto;
   max-width: 960px;
   width: calc(100% - 40px);
   overflow: hidden;
   position: relative;

   &::after {
      padding-top: 56.25%;
      display: block;
      content: '';
   }

   ${devices.mobile} {
      width: calc(100% - 60px);
   }

   ${devices.mobileL} {
      width: calc(100% - 80px);
   }

   ${devices.tabletXL} {
      width: 100%;
   }

   ${({ marginTop, marginBottom }) => css`
      margin: ${`${marginTop}px auto ${marginBottom}px`};
   `}

   iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
   }
`;

const Error = styled.div`
   padding: 20px 40px;
   background-color: #F50253;
   color: #fff;
   font-size: 18px;
   line-height: 1.5;
   text-align: center;

   > strong {
      font-weight: 800;
   }
`;

export default DatoCmsVideoPlayer;
