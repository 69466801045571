import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface DatoCmsFullWidthImageProps {
   image: IGatsbyImageData;
   marginTop: number;
   marginBottom: number;
   alt: string;
}

function DatoCmsFullWidthImage({
  alt, image, marginTop, marginBottom,
}: DatoCmsFullWidthImageProps) {
  return (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <GatsbyImage image={image} alt={alt} />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ marginBottom: number; marginTop: number; }>`
   width: 100%;
   margin: 0 auto;

   ${({ marginTop, marginBottom }) => css`
      margin-top: ${`${marginTop}px`};
      margin-bottom: ${`${marginBottom}px`};
   `}
`;

export default DatoCmsFullWidthImage;
