import React from 'react';
import styled, { css } from 'styled-components';
import { StructuredText } from 'react-datocms';
import devices from 'theme/devices';

interface DatoCmsCustomHeadlineProps {
   marginTop: number;
   marginBottom: number;
   headline: any;
   fontSize: number;
   lineHeight: number;
}

function DatoCmsCustomHeadline({
  headline, fontSize, lineHeight, marginTop, marginBottom,
}: DatoCmsCustomHeadlineProps) {
  return (
    <Wrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      fontSize={fontSize}
      lineHeight={lineHeight}
    >
      <StructuredText data={headline} />
    </Wrapper>
  );
}

interface WrapperStyledProps {
   marginTop: number;
   marginBottom: number;
   fontSize: number;
   lineHeight: number;
}

const Wrapper = styled.div<WrapperStyledProps>`
   width: calc(100% - 40px);
   max-width: 960px;
   margin: 0 auto;

   h1, h2, h3, h4, h5, h6 {
      margin: 0;
      width: 100%;
      display: block;
   }

   ${devices.mobile} {
      width: calc(100% - 60px);
      margin: 0 auto;
   }

   ${devices.mobileL} {
      width: calc(100% - 80px);
      margin: 0 auto;
   }

   ${devices.tabletXL} {
      width: 100%;
      margin: 0 auto;
   }

   ${({
    marginTop, marginBottom, lineHeight, fontSize,
  }) => css`
      margin-top: ${`${marginTop}px`};
      margin-bottom: ${`${marginBottom}px`};
      font-size: ${`${fontSize}px`};
      line-height: ${lineHeight};


   ${devices.mobile} {
      margin-top: ${`${marginTop}px`};
      margin-bottom: ${`${marginBottom}px`};
   }

   ${devices.mobileL} {
      margin-top: ${`${marginTop}px`};
      margin-bottom: ${`${marginBottom}px`};
   }

   ${devices.tabletXL} {
      margin-top: ${`${marginTop}px`};
      margin-bottom: ${`${marginBottom}px`};
   }

      > p {
         font-size: ${`${fontSize}px !important`};
         line-height: ${`${lineHeight} !important`};
      }
   `}
`;

export default DatoCmsCustomHeadline;
