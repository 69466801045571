import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import devices from 'theme/devices';

interface DatoCmsContentImageProps {
   image: IGatsbyImageData;
   marginTop: number;
   marginBottom: number;
   alt: string;
}

function DatoCmsContentImage({
  alt, image, marginTop, marginBottom,
}: DatoCmsContentImageProps) {
  return (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <GatsbyImage image={image} alt={alt} />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ marginBottom: number; marginTop: number; }>`
   width: calc(100% - 40px);
   max-width: 960px;
   margin: 0 auto;

   ${({ marginTop, marginBottom }) => css`
      margin-top: ${`${marginTop}px`};
      margin-bottom: ${`${marginBottom}px`};
   `}

   ${devices.mobile} {
      width: calc(100% - 60px);
   }

   ${devices.mobileL} {
      width: calc(100% - 80px);
   }

   ${devices.tabletXL} {
      width: 100%;
   }
`;

export default DatoCmsContentImage;
